<template>
    <div>
        <!-- TODO: make CoverComponent -->
        <v-app-bar fixed v-if="hasChanged">
            <v-img 
            :src="require('@/assets/logo-teal.png')"
            max-height="30"
            max-width="200"
            contain
            @click="$router.push('/')"
            style="cursor: pointer;"
            v-if="!$vuetify.breakpoint.mdAndDown"
            ></v-img>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined class="mr-3 pa-5 px-6 font-weight-black elevation-0"
                @click="discard()" dark>Descartar</v-btn>
            <v-btn color="teal accent-4" class="mr-3 pa-5 px-6 font-weight-black elevation-0"
                @click="save()" dark>Guardar</v-btn>
        </v-app-bar>
        <v-img :src="croppedUrlImage" alt="Resultado del recorte" height="300px">
            <v-toolbar class="transparent" flat>
                <v-btn class="px-6 py-4 translucient-dark rounded-lg text-none" dark :href="businessUrlProfile" target="_blank">
                    <v-icon left>mdi-eye</v-icon>
                    Vista previa
                </v-btn>
                <v-spacer></v-spacer>
                <v-menu offset-y offset-x class="pa-3 rounded-lg">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small class="px-3 py-5 translucient-dark rounded-lg text-none" dark v-bind="attrs"
                            v-on="on">
                            <v-icon dense>mdi-camera</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item link>
                            <v-list-item-title class="body-2" @click="imageDialog = true">
                                <v-icon left>mdi-crop</v-icon>
                                Editar portada
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item link>
                            <v-list-item-title class="body-2" @click="triggerFileUpload">
                                <v-icon left>mdi-image-plus</v-icon>
                                Subir portada
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item link>
                            <v-list-item-title class="body-2" @click="removeBookingBgImage">
                                <v-icon left>mdi-delete-outline</v-icon>
                                Eliminar portada
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
        </v-img>
        <input type="file" ref="bgImageInput" @change="handleBgImageUpload" style="display: none;" accept="image/*" />
        <CropperDialog v-model="imageDialog" :imageUrl="originalUrlImage" :originalImageFile="originalImageFile" :businessId="business.id"/>
        <v-toolbar flat height="130" class="px-lg-4">
            <v-badge bottom overlap color="transparent">
                <template v-slot:badge>
                    <v-chip class="mt-n6 ml-n6 pa-4 teal accent-4" @click="triggerLogoUpload"
                        style="border: 3px solid white !important;">
                        <v-icon color="white" left>mdi-camera</v-icon>
                    </v-chip>
                </template>
                <v-avatar size="80" class="rounded-xl" color="grey lighten-3">
                    <v-img v-if="business.logoUrlImage" :src="business.logoUrlImage" alt="Logo de la empresa" @click="triggerLogoUpload" style="cursor: pointer;" cover/>
                    <v-icon v-else x-large color="grey">mdi-store</v-icon>
                </v-avatar>
            </v-badge>

            <!-- Input oculto para seleccionar la imagen -->
            <input type="file" ref="logoInput" @change="handleLogoUpload" accept="image/*" style="display: none;" />

            <v-toolbar-title class="ml-6">
                <h2 :style="$vuetify.breakpoint.mdAndDown ? 'font-size: 20px;' : 'font-size: 25px;'" class="text-wrap">
                    {{ business.name }}
                </h2>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-menu offset-y offset-x left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small outlined color="grey lighten-1" class="px-3 py-5 rounded-lg text-none" v-bind="attrs"
                        v-on="on">
                        <v-icon dense color="grey darken-1">mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-btn color="white" class="px-5 py-6 text-none rounded-lg red--text text--lighten-1">
                    <v-icon>mdi-delete-outline</v-icon>
                    <span class="ml-2">Eliminar cuenta</span>
                </v-btn>
            </v-menu>
        </v-toolbar>
        <v-container fluid class="pa-lg-8">
            <v-card outlined class="rounded-lg mb-5">
                <v-card-title class="py-3 px-6">
                    <h4 style="font-size: 18px;">Detalles de la cuenta</h4>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="py-3 px-6">
                    <h4 class="black--text">Nombre de la empresa</h4>
                    <v-text-field v-model="business.name" label="Nombre de la cuenta" outlined dense class="mt-2"></v-text-field>
                    <h4 class="black--text">Eslogan</h4>
                    <v-textarea v-model="business.slogan" label="El eslogan de tu empresa" outlined dense class="mt-2"></v-textarea>
                    <h4 class="black--text">Descripción</h4>
                    <v-textarea v-model="business.description" label="Describe brevemente tu empresa" outlined dense class="mt-2"></v-textarea>
                </v-card-text>
            </v-card>
            <v-card outlined class="rounded-lg mb-5">
                <v-card-title class="py-3 px-6">
                    <h4 style="font-size: 18px;">Contacto</h4>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="py-3 px-6">
                    <v-row>
                        <v-col cols="12" md="6" class="pr-10">
                            <h4 class="black--text">Teléfono</h4>
                            <PhoneInput 
                            v-model="business.phoneNumber" 
                            :phoneIso2.sync="business.phoneIso2" 
                            :phoneCode.sync="business.phoneCode" 
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <h4 class="black--text">Correo electrónico</h4>
                            <v-text-field v-model="business.email" label="Correo electrónico" outlined dense class="mt-2"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card outlined class="rounded-lg mb-5">
                <v-card-title class="py-3 px-6">
                    <h4 style="font-size: 18px;">Canales de comunicación</h4>
                </v-card-title>
                <v-divider></v-divider>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card-text class="py-3 px-6">
                            <h4 class="black--text">
                                URL página web 
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon dense color="primary" right v-bind="attrs"
                                        v-on="on">mdi-information</v-icon>
                                    </template>
                                    <div style="width: 200px;" class="font-weight-regular"> Comparte la URL de tu página web para que tus clientes puedan visitarla.</div>
                                </v-tooltip>
                            </h4>
                            <v-text-field v-model="business.websiteUrl" label="URL del negocio" outlined dense class="mt-2"></v-text-field>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card-text class="py-3 px-6">
                            <h4 class="black--text">
                                URL del perfil de Facebook 
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon dense color="primary" right v-bind="attrs"
                                        v-on="on">mdi-information</v-icon>
                                    </template>
                                    <div style="width: 200px;" class="font-weight-regular"> Comparte la URL del perfil de Facebook de tu empresa para que tus clientes puedan visitarlo.</div>
                                </v-tooltip>
                            </h4>
                            <v-text-field v-model="business.facebookUrl" label="URL de la página de Facebook" outlined dense class="mt-2"></v-text-field>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
            <v-card outlined class="rounded-lg mb-5">
                <v-card-title class="py-3 px-6">
                    <h4 style="font-size: 18px;">Dirección del negocio</h4>
                    <v-spacer></v-spacer>
                    <v-btn plain color="primary" class="font-weight-bold pr-0" @click="addressDialog=true">Agregar</v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="py-3 px-6" v-if="addressObject.country == ''">
                    No se ha añadido ninguna dirección. Haz clic para añadir una nueva dirección para rellenar tu dirección comercial.
                </v-card-text>
                <v-card-text  v-else>
                    <v-card class="pa-5 elevation-0">
                        <v-row v-if="addressObject.address !== ''">
                            <v-col cols="6" md="3">
                                <span class="font-weight-bold">Calle</span>
                            </v-col>
                            <v-col cols="6" md="9">
                                <span class="body-1">{{ addressObject.address }}</span>
                            </v-col>
                        </v-row>
                        <v-row v-if="addressObject.locality !== ''">
                            <v-col cols="6" md="3">
                                <span class="font-weight-bold">Localidad</span>
                            </v-col>
                            <v-col cols="6" md="9">
                                <span class="body-1">{{ addressObject.locality }}</span>
                            </v-col>
                        </v-row>
                        <v-row v-if="addressObject.city !== ''">
                            <v-col cols="6" md="3">
                                <span class="font-weight-bold">Ciudad</span>
                            </v-col>
                            <v-col cols="6" md="9">
                                <span class="body-1">{{ addressObject.city }}</span>
                            </v-col>
                        </v-row>
                        <v-row v-if="addressObject.country !== ''">
                            <v-col cols="6" md="3">
                                <span class="font-weight-bold">País</span>
                            </v-col>
                            <v-col cols="6" md="9">
                                <span class="body-1">{{ addressObject.country }}</span>
                                <span class="fi ml-2" :class="`fi-${addressObject.countryCode?.toLowerCase()}`"></span>
                            </v-col>
                        </v-row>
                        <v-row v-if="addressObject.postalCode !== ''">
                            <v-col cols="6" md="3">
                                <span class="font-weight-bold">Código Postal</span>
                            </v-col>
                            <v-col cols="6" md="9">
                                <span class="body-1">{{ addressObject.postalCode }}</span>
                            </v-col>
                        </v-row>
                        <v-row v-if="addressObject.latitude > 0">
                            <v-col cols="6" md="3">
                                <span class="font-weight-bold">Ubicación</span>
                            </v-col>
                            <v-col cols="6" md="9">
                                <span class="body-1">
                                    {{ addressObject.latitude ? `${addressObject.latitude}ºN` : '' }} 
                                    {{ addressObject.longitude ? `, ${addressObject.longitude}ºW` : '' }}
                                </span>
                            </v-col>
                        </v-row>
                        <!-- TODO: Incluir mapa -->
                    </v-card>
                </v-card-text>
            </v-card>
            <AddressDialog v-model="addressDialog" :data="addressObject" @save="handleSaveAddress"></AddressDialog>
            <v-card outlined class="rounded-lg mb-5">
                <v-card-title class="py-3 px-6">
                    <h4 style="font-size: 18px;">Horario laboral</h4>
                    <v-spacer></v-spacer>
                    <v-btn plain color="primary" class="font-weight-bold pr-0" @click="scheduleDialog=true">Editar</v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-6">
                    <v-row v-for="day in business.schedule" :key="`weekday-${day.day}`">
                        <v-col cols="12" md="3">
                            <span class="font-weight-bold body-1 text-capitalize">{{ getWeekDay(day.day) }}</span>
                        </v-col>
                        <v-col cols="12" md="3" class="d-flex flex-column justify-center text-center">
                            <span v-if="!day.isOpen" class="body-1 font-weight-regular">
                                Cerrado
                            </span>
                            <template v-else>
                                <span v-for="(item, idx) in day.opening" :key="`tt-opening-${day.day}-${idx}`" class="body-1 font-weight-regular">
                                    {{ item.open }} - {{ item.close }}
                                </span>
                            </template>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <ScheduleDialog v-model="scheduleDialog" :schedule.sync="business.schedule"></ScheduleDialog>
        </v-container>
    </div>
    <!-- <h2>{{ canvas?.toDataURL() }}</h2> -->
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import 'vue-advanced-cropper/dist/style.css';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import moment from 'moment'

import AddressDialog from '@/components/business/AddressDialog.vue'
import CropperDialog from '@/components/business/CropperDialog.vue'
import ScheduleDialog from '@/components/business/ScheduleDialog.vue'
import PhoneInput from '@/components/common/PhoneInput.vue'

export default {
    name: 'DetailsView',
    components: {
        AddressDialog, 
        CropperDialog,
        ScheduleDialog, 
        PhoneInput
    },
    data() {
        return {
            defaultBusiness: {},
            hasChanged: false,
            addressDialog: false,
            scheduleDialog: false,
            imageDialog: false,
            originalImageFile: null
        }
    },
    computed: {
        ...mapGetters(['business']),
        businessUrlProfile() {
            return `${process.env.VUE_APP_URL_CLIENT}/${this.business.subdomain}`
        },
        croppedUrlImage() {
            return this.business.croppedUrlImage || `/booking-bg.webp`
        },
        originalUrlImage() {
            return this.business.originalUrlImage || `/booking-bg.webp`
        },
        addressObject: {
            get() {
                return {
                    address: this.business.address,
                    locality: this.business.locality,
                    city: this.business.city,
                    country: this.business.country,
                    countryCode: this.business.countryCode,
                    postalCode: this.business.postalCode,
                    latitude: this.business.latitude,
                    longitude: this.business.longitude,
                }
            },
            set(val) {
                this.business.address = val.address
                this.business.locality = val.locality
                this.business.city = val.city
                this.business.country = val.country
                this.business.countryCode = val.countryCode
                this.business.postalCode = val.postalCode
                this.business.latitude = val.latitude
                this.business.longitude = val.longitude
            }
        }
    },
    watch: {
        business: {
            handler (val) {
                const defaultBusiness = JSON.parse(JSON.stringify(this.defaultBusiness))
                delete defaultBusiness.croppedUrlImage
                delete defaultBusiness.originalUrlImage
                delete defaultBusiness.logoUrlImage
                const business = JSON.parse(JSON.stringify(val))
                delete business.croppedUrlImage
                delete business.originalUrlImage
                delete business.logoUrlImage
                if (JSON.stringify(defaultBusiness) !== '{}' && JSON.stringify(business) !== JSON.stringify(defaultBusiness)) {
                    this.hasChanged = true
                }
            },
            deep: true
        },
    },
    async created() {
        await this.fetchBusiness()
        this.defaultBusiness = JSON.parse(JSON.stringify(this.business))
    },
    methods: {
        ...mapActions(['fetchBusiness', 'updateBusiness', 'removeBookingBgImage', 'uploadLogoImage']),
        ...mapMutations(['setBusiness']),
        getWeekDay(day) {
            return moment().locale('es').day(day).format('dddd')
        },
        handleSaveAddress(address) {
            this.addressObject = address
        },
        discard() {
            this.setBusiness(Object.assign({}, this.defaultBusiness));
            this.hasChanged = false
        },
        async save() {
            await this.updateBusiness(this.business)
            await this.fetchBusiness()
            this.hasChanged = false;
        },
        triggerFileUpload() {
            this.$refs.bgImageInput.click();
        },
        handleBgImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.originalImageFile = file;
                this.business.originalUrlImage = URL.createObjectURL(file);
                this.imageDialog = true;
            }
        },
        async removeBgImage() {
            await this.removeBookingBgImage()
        },
        triggerLogoUpload() {
            this.$refs.logoInput.click();  // Esto abrirá el diálogo para seleccionar un archivo
        },
        async handleLogoUpload(event) {
            const file = event.target.files[0];
            if (file) {
                // Creamos un Blob o File object para subir al store
                const blob = file;
                try {
                    // Llamamos al action de Vuex para subir la imagen
                    await this.uploadLogoImage({ blob });
                    // this.$notify({ type: 'success', message: 'Logo subido correctamente.' }); // Notificación opcional
                } catch (error) {
                    // this.$notify({ type: 'error', message: 'Hubo un error al subir el logo.' }); // Notificación opcional
                }
            }
        }
    }
}
</script>
